/** @jsx jsx */
import { jsx, Text, Container, Flex, AspectRatio } from 'theme-ui';
import React from 'react';
import { graphql } from 'gatsby';

import { BlockContent } from '../components/block-content';
import SEO from '../components/seo';
import GraphQLErrorList from '../components/graphql-error-list';
import { Stack, Row, Grid, Col } from '../components/grid';
import { Anchor } from '../components/link';
import { Twitter, Facebook, Insta, LinkedIn } from '../icons';
import { Helmet } from 'react-helmet';
import { PromoBanner } from '../components/promo-banner';

const Icons = {
  insta: Insta,
  twtr: Twitter,
  fb: Facebook,
  lnkd: LinkedIn,
};

const Labels = {
  insta: 'Instagram',
  twtr: 'Twitter',
  fb: 'Facebook',
  lnkd: 'LinkedIn',
};

const AboutPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <>
        <GraphQLErrorList errors={errors} />
      </>
    );
  }
  return (
    <>
      <SEO {...data.page.seo} />
      {data.page.promoBanner && <PromoBanner {...data.page.promoBanner} />}
      <Container>
        <Row>
          <Grid columns={[12]}>
            <Col start={[1, 2, 3]} span={[12, 11, 10]}>
              <BlockContent blocks={data.page.about} />
            </Col>
          </Grid>
        </Row>
      </Container>
      <Container
        sx={{
          borderTop: 'line',
          borderBottom: 'line',
          borderColor: 'line',
          backgroundColor: ['bg.1'],

          pt: [8, 7, 9],
          pb: [9, 8, 10],
        }}
      >
        <Grid columns={[12]} gapY={[8, 9, 10]}>
          {/* <Col start={[1, 1, 1, 1]} span={[12, 11, 10, 2]}>
            <Text as="h2" variant="section_header">
              Contact us
            </Text>
          </Col> */}
          <Col start={[1, 2, 3]} span={[12, 10, 10]}>
            <Grid columns={[1, 1, 2, 4]} gap={true} gapY={[8]}>
              <Col>
                <address>
                  <Text as="h5" variant="small" sx={{ color: 'txt.3' }}>
                    Address
                  </Text>
                  <Text>{data.page.contact.address}</Text>
                  <Anchor to={`tel:${data.page.contact.telephone}`} variant={'buttons.link'} email>
                    {data.page.contact.telephone}
                  </Anchor>
                </address>
              </Col>
              <Col span={[1, 1, 2]}>
                <Stack gap={[8, 9, 10]}>
                  {data.page.contact.emails.map(({ title, email }) => (
                    <div key={email}>
                      <Text as="h5" variant="small" sx={{ color: 'txt.3' }}>
                        {title}
                      </Text>
                      <Anchor to={`mailto:${email}`} variant={'buttons.link'} email>
                        {email}
                      </Anchor>
                    </div>
                  ))}
                </Stack>
              </Col>
            </Grid>
          </Col>
        </Grid>
      </Container>

      <Container>
        <Row>
          <Grid columns={12} gap={[false]}>
            {/* <Col start={[1, 1, 1, 1]} span={[12, 11, 10, 2]}>
              <Text as="h2" variant="section_header">
                Our Team
              </Text>
            </Col> */}

            <Col start={[1, 2, 3]} span={[12, 11, 10]}>
              <Grid columns={[1, 2, 2, 4]} gap={true} gapY={[16]}>
                {data.page.people.map((person) => (
                  <Stack gap={[8, 9, 10]} key={person.name}>
                    <div>
                      <AspectRatio ratio="1">
                        <img src={person.photo.asset.url} alt={person.name} />
                      </AspectRatio>
                    </div>
                    <Stack gap={[4]}>
                      <Text as="h3" variant="h4">
                        {person.name}
                      </Text>
                      <BlockContent as="div" blocks={person.info} />
                    </Stack>
                    <Flex sx={{ ml: -5 }}>
                      {person.links.map(({ href, label }) => {
                        const Icon = Icons[label];
                        return (
                          <Flex key={href}>
                            <Anchor
                              external
                              href={href}
                              sx={{ mx: 5, width: 10 }}
                              variant={'buttons.link'}
                              aria-label={Labels[label]}
                            >
                              <Icon />
                            </Anchor>
                          </Flex>
                        );
                      })}
                    </Flex>
                  </Stack>
                ))}
              </Grid>
            </Col>
          </Grid>
        </Row>
      </Container>
    </>
  );
};

export const query = graphql`
  query AboutPageQuery {
    page: sanityPageAbout {
      id
      seo {
        ...SanityPageSeoFragment
      }
      promoBanner {
        ...SanityPromoBannerFragment
      }
      about: _rawAbout(resolveReferences: { maxDepth: 10 })
      contact {
        emails {
          title
          email
        }
        address
        telephone
      }
      people {
        _id
        name
        info: _rawInfo(resolveReferences: { maxDepth: 10 })

        photo {
          asset {
            url
            # localFile {
            #   childImageSharp {
            #     fixed(width: 200, grayscale: true) {
            #       srcWebp
            #       src
            #     }
            #   }
            # }
          }
        }
        links {
          label
          href
        }
      }
      seo {
        ...SanityPageSeoFragment
      }
    }
  }
`;

export default AboutPage;
